import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

// COMPONENTS
import Icon from 'components/Icon';

class Nav extends Component {
	render() {
		const pathname = this.props.location.pathname;

		return (
			<nav>
				<Link to="/" className={pathname == '/' ? 'current' : undefined}><Icon icon="chart-line" size="12" />Dashboard</Link>

				<div className="menu-title">KUNDEN</div>
				<Link to="/customers" className={pathname == '/customers' ? 'current' : undefined}><Icon icon="users" size="12" />Meine Kunden</Link>
				<Link to="/invoices" className={pathname == '/invoices' ? 'current' : undefined}><Icon icon="file-invoice-dollar" size="12" />Rechnungen</Link>

				<div className="menu-title">E-MAIL</div>
				<Link to="/email-accounts" className={pathname == '/email-accounts' ? 'current' : undefined}><Icon icon="envelope" size="12" />E-Mail Accounts</Link>

				<div className="menu-title">QUICKLINKS</div>
				<a href="https://mail.leonmarkus.de/" target="_blank" rel="noopener noreferrer"><Icon icon="envelope" size="12" />ox</a>
				<a href="https://kcm.keyweb.de/" target="_blank" rel="noopener noreferrer"><Icon icon="feather-alt" size="12" />Domain Registrar</a>
			</nav>
		);
	}
}
export default withRouter(Nav);