import React, { Component, Fragment } from 'react';
//import { Transition, animated } from 'react-spring';

export default class Index extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		return (
			<Fragment>
				<div className="main-caption">
					Dashboard
				</div>
				<div className="main-content">
				Hello World from Chimpar!<br />
				This is <b>Dashboard</b>!
				</div>
			</Fragment>
		);
	}
}