import React, { Component } from 'react';

export default class Icon extends Component {
	render() {		
		const { icon = 'question', type = 'fas', size = 16, lineHeight, onClick, flipHorizontal, flipVertical, marginLeft, marginRight, className } = this.props;

		const classes = [type, `fa-${icon}`];
		if (flipHorizontal) classes.push('fa-flip-horizontal');
		if (flipVertical) classes.push('fa-flip-vertical');
		if (className) classes.push(className);

		return (
			<i className={classes.join(' ')} style={{
				marginLeft, 
				marginRight,
				fontSize: `${size}px`,
				lineHeight: lineHeight ? `${lineHeight}px` : undefined,
				verticalAlign: lineHeight ? 'bottom' : undefined
			}} onClick={onClick} />
		);
	}
}