import React, { Component } from 'react';

import './styles.scss';

export default class Input extends Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
	}

	render() {
		const {
			type = 'text',
			value,
			label,
			options,
			disabled
		} = this.props;

		if (type == 'select') {
			return (
				<div className="input">
					<select onChange={this.onChange} disabled={disabled}>
						{ options.length > 0 && options.map((o, index) => <option key={index} value={o.value}>{ o.label }</option>)}
					</select>
					{ label && <label className="floating">{ label } </label> }
				</div>
			);
		} else if (type =='textarea') {
			return (
				<div className="input">
					<textarea onChange={this.onChange} value={value} disabled={disabled} />
					{ label && <label className="floating">{ label } </label> }
				</div>
			);
		}

		return (
			<div className="input">
				<input type={type} value={value} disabled={disabled} onChange={this.onChange} />
				{ label && <label className={(type == 'date' || value !== '') ? 'floating' : undefined}>{ label } </label> }
			</div>
		);
	}

	onChange(e) {
		const { onChange: doChange = () => null } = this.props;
		doChange(e.currentTarget.value);
	}
}