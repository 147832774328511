import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

// COMPONENTS
import Icon from 'components/Icon';

class Button extends Component {
	render() {
		const {
			circle = false,
			small = false,
			block = false,
			outline = false,
			href,
			newtab,
			icon,
			iconSize,
			color = 'default',
			nohover,
			action,
			modal,
			onClick,
			children
		} = this.props;
		let { dark } = this.props;

		let mColor;
		if (typeof color != 'undefined' && color != null && color.charAt(0) != '#') {
			switch (color) {
			case 'cherry-tomato':
				mColor = '#E94B3C';
				break;
			case 'little-boy-blue':
				mColor = '#6F9FD8';
				break;
			case 'ultra-violet':
				mColor = '#6B5B95';
				break;
			case 'lime-punch':
				mColor = '#BFD641';
				break;
			case 'sargasso-sea':
				mColor = '#485167';
				break;
			case 'meerkat':
				mColor = '#A9754F';
				break;
			case 'transparent':
				mColor = 'rgba(255, 255, 255, 0)';
				break;
			case 'default':
				mColor = '#6c757d';
				break;
			default:
				mColor = undefined;
				break;
			}
		} else mColor = color;

		const classes = ['btn'];
		if (circle) classes.push('circle');
		if (small) classes.push('small');
		if (block) classes.push('block');
		if (outline) classes.push('outline dark');
		else if (dark) classes.push('dark');
		if (nohover) classes.push('nohover');

		return (
			<MyButton
				href={href}
				newtab={newtab}
				className={classes.join(' ')}
				style={{
					marginLeft: action ? '8px' : (modal ? '12px' : undefined),
					borderColor: mColor,
					backgroundColor: mColor
				}}
				onClick={onClick}>
				{ icon && <Icon icon={icon} size={iconSize ? iconSize : (small ? 12 : undefined)} marginRight={children ? (iconSize ? Math.round(iconSize/3) : 6) : undefined} /> }
				{ children }
			</MyButton>
		);
	}
}

class MyButton extends Component {
	render() {
		const { href, newtab } = this.props;

		const newProps = { ...this.props, href: undefined, newtab: undefined };

		if (newtab) return (<a target="_blank" rel="noopener noreferrer" {...newProps} href={href} />);
		else if (href) return (<Link to={href} {...newProps} />);
		else return (<button {...newProps} />);
	}
}

export default Button;