import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { loadProgressBar } from 'axios-progress-bar';
import { Transition, animated } from 'react-spring';
import moment from 'moment';

import 'base.scss';
import 'axios-progress-bar/dist/nprogress.css';

// SERVICES
import Storage from 'services/storage';
import Api from 'services/api';

// PAGES
import IndexPage from 'pages/index';
import CustomersPage from 'pages/customers';
import CustomerPage from 'pages/customer';
import InvoicesPage from 'pages/invoices';
import InvoicePage from 'pages/invoice';
import EmailAccountsPage from 'pages/email-accounts';

// COMPONENTS
import Nav from 'components/Nav';

import Loader from 'components/Loader';
import Icon from 'components/Icon';
import Button from 'components/Button';

// IMAGES
import UnlockBackground from 'images/bg-unlock.jpg';
import UserImage from 'images/users/0.jpg';

export default class App extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			onload: true,

			email: { focus: false, val: '' },
			password: { focus: false, val: '' },

			user: null,
			menuCompressed: false,
			userMenu: false,
			topbarSearch: ''
		};

		this.storage = new Storage();
		this.api = new Api();

		// CHECK FOR HOOKED SESSION
		const sess = this.storage.get('sess');
		if (typeof sess != 'undefined' && sess != null && sess != '') {
			this.state = {
				...this.state,
				sesshook: sess
			};
		}

		loadProgressBar();
	}

	componentDidMount() {
		// VALIDATE HOOKED SESSION
		if (typeof this.state.sesshook != 'undefined' && this.state.sesshook != null) {
			this.api.setSession(this.state.sesshook);
			this.api.get('me').then(user => {
				this.setState({ user, onload: false });
			});
		} else this.setState({ onload: false });
	}

	render() {
		return (
			<Router>
				{ this.state.onload ? 
					( <div id="onload" style={{ backgroundImage: `url('${UnlockBackground}')`}}>
						<Loader size="80" />
					</div> )
					:
					( this.state.user != null ? 
						(<div id="app" className={this.state.onload ? 'onload' : null}>
							<div id="topbar" className={this.state.menuCompressed ? 'expanded' : null}>
								<div className={(this.state.topbarSearch.length > 0) ? 'topbar-search open' : 'topbar-search'}>
									<input type="text" placeholder="Suche..." onChange={event => this.topbarSearchInput(event)} onKeyPress={event => this.topbarSearchKeypress(event)} />
									<Icon icon="search" size="18" onClick={this.topbarSearchDo.bind(this)} />
								</div>
								<div className="topbar-actions">
									<div className="topbar-btn">
										<Icon icon="user-circle" size="20" />
									</div>
									<div className="topbar-btn" onClick={this.userMenuToggle.bind(this)}>
										<Icon icon="list-ul" size="20" />
									</div>
								</div>
							</div>
							<div id="menu" className={this.state.menuCompressed ? 'compressed' : null}>
								<div className="app-title">CHIMPAR</div>
								<div className="menu-toggle" onClick={this.menuCompressionToggle.bind(this)}>
									<span className="bar1"></span>
									<span className="bar2"></span>
									<span className="bar3"></span>
								</div>
								<div className="user" style={{ backgroundColor: this.state.user.color }}>
									<Transition native from={{ width: '0%', opacity: 0 }} enter={{ width: '100%', opacity: 1 }} leave={{ width: '0%', opacity: 0 }}>
										{ !this.state.menuCompressed && (styles => <div className="info">
											<animated.div className="name" style={styles}>{ `${this.state.user.name} ${this.state.user.surname}` }</animated.div>
											<animated.div className="company" style={styles}>{ this.state.user.company }</animated.div>
										</div> )}
									</Transition>
									<Transition native from={{ opacity: 0 }} enter={{ opacity: 1 }} leave={{ opacity: 0 }}>
										{ this.state.menuCompressed && (styles => <div className="info">
											<animated.div className="initials" style={styles}>{ `${this.state.user.name.charAt(0)} ${this.state.user.surname.charAt(0)}` }</animated.div>
											<animated.div className="accent" style={styles} />
										</div> )}
									</Transition>
									<img src={UserImage} alt="You" />
								</div>
								<Nav />
							</div>
							<Transition native from={{ right: '-250px', opacity: 0 }} enter={{ right: '0px', opacity: 1 }} leave={{ right: '-250px', opacity: 0 }}>
								{ this.state.userMenu && (({ right, opacity }) => <Fragment>
									<animated.div id="user-menu-overlay" style={{ opacity }} onClick={this.userMenuToggle.bind(this)} />
									<animated.div id="user-menu" style={{ right, opacity }}>
										<Icon icon="times" size="16" className="close" onClick={this.userMenuToggle.bind(this)} />
										<div className="date">
											{ moment().format('dddd') }<br />
											{ moment().format('D') }<small>{ moment().format('Do').substr(-2) }</small> { moment().format('MMMM') }
										</div>
										<div className="divider" />
										<Button color="#3C4252" block onClick={this.lock.bind(this)}>Lock Session</Button>
									</animated.div>
								</Fragment> )}
							</Transition>
							<div id="main" className={this.state.menuCompressed ? 'expanded' : null}>
								<Switch>
									<Route exact path="/" component={({ location, match, history }) => 
										<IndexPage router={{...location, params: match.params, push: history.push}} api={this.api} user={this.state.user} />
									} />
									<Route exact path="/customers" component={({ location, match }) => 
										<CustomersPage router={{...location, params: match.params, push: history.push}} api={this.api} user={this.state.user} />
									} />
									<Route exact path="/customers/:id" component={({ location, match }) => 
										<CustomerPage router={{...location, params: match.params, push: history.push}} api={this.api} user={this.state.user} />
									} />
									<Route exact path="/invoices" component={({ location, match }) => 
										<InvoicesPage router={{...location, params: match.params, push: history.push}} api={this.api} user={this.state.user} />
									} />
									<Route exact path="/invoices/:id" component={({ location, match }) => 
										<InvoicePage router={{...location, params: match.params, push: history.push}} api={this.api} user={this.state.user} />
									} />
									<Route exact path="/email-accounts" component={({ location, match }) => 
										<EmailAccountsPage router={{...location, params: match.params, push: history.push}} api={this.api} user={this.state.user} />
									} />
									
									{ /*<Route component={NotFoundPage} />*/ }
								</Switch>
							</div>
						</div>)
						:
						( <div id="unlock" style={{ backgroundImage: `url('${UnlockBackground}')`}}>
							<div className="card">
								<h1>YOUR SESSION IS LOCKED</h1>
								<div className={(this.state.email.focus || this.state.email.val.length > 0) ? 'floating' : null}>
									<label htmlFor="email">E-MAIL</label>
									<input id="email" type="email" name="email" onFocus={this.unlockOnFocusEmail.bind(this)} onBlur={this.unlockOnBlurEmail.bind(this)} onChange={event => this.unlockOnChangeEmail(event)} />
								</div>
								<div className={(this.state.password.focus || this.state.password.val.length > 0) ? 'floating' : null}>
									<label htmlFor="password">PASSWORD</label>
									<input id="password" type="password" name="password" onFocus={this.unlockOnFocusPassword.bind(this)} onBlur={this.unlockOnBlurPassword.bind(this)} onChange={event => this.unlockOnChangePassword(event)} onKeyPress={event => this.unlockOnKeyPress(event)} />
								</div>
								<button onClick={this.unlock.bind(this)}>UNLOCK</button>
							</div>
						</div> )
					)
				}
			</Router>
		);
	}

	menuCompressionToggle() { this.setState({ menuCompressed: !this.state.menuCompressed }); }
	userMenuToggle() { this.setState({ userMenu: !this.state.userMenu }); }
	topbarSearchInput(event) { this.setState({topbarSearch: event.target.value}); }
	topbarSearchKeypress(event) { if (event.key === 'Enter') this.topbarSearchDo(); }
	topbarSearchDo() {
		alert(this.state.topbarSearch);
	}

	/* AUTH */
	unlockOnFocusEmail() { this.setState({ email: {focus: true, val: this.state.email.val} }); }
	unlockOnBlurEmail() { this.setState({ email: {focus: false, val: this.state.email.val} }); }
	unlockOnFocusPassword() { this.setState({ password: {focus: true, val: this.state.password.val} }); }
	unlockOnBlurPassword() { this.setState({ password: {focus: false, val: this.state.password.val} }); }
	unlockOnChangeEmail(event) { this.setState({ email: {focus: this.state.email.focus, val: event.target.value} }); }
	unlockOnChangePassword(event) { this.setState({ password: {focus: this.state.password.focus, val: event.target.value} }); }
	unlockOnKeyPress(event) { if (event.key === 'Enter') this.unlock(); }
	unlock() {
		this.api.post('unlock', { email: this.state.email.val, password: this.state.password.val }).then(data =>  {
			this.setState({
				email: {focus: false, val: ''},
				password: {focus: false, val: ''}
			});
			this.storage.set('sess', data.sess, 1); // 1 Day
			this.api.setSession(data.sess);
			
			this.setState({ user: data.user });
		});
	}
	lock() {
		this.storage.del('sess');
		this.api.removeSession();
		this.setState({ user: null });
	}
}