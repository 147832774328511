import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
//import { Transition, animated } from 'react-spring';

// COMPONENTS
import Icon from 'components/Icon';
import Button from 'components/Button';
import Table from 'components/Table';

export default class Customer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			customer: {},
			invoices: []
		};
	}

	componentDidMount() {
		const { router, api, user } = this.props;
		const { id } = router.params;

		api.get('/customer', { id }).then(mCustomer => {
			const customer = {
				...mCustomer,
				edited_label: mCustomer.edited ? moment(mCustomer.edited).format('HH:mm [//] DD.MM.YYYY') : '',
				created_label: mCustomer.created ? moment(mCustomer.created).format('HH:mm [//] DD.MM.YYYY') : ''
			};
			this.setState({ customer });
		});
		
		api.get('/invoices', { customer: id }).then(mInvoices => {
			const invoices = mInvoices.map(i => {
				i.date_label = moment(i.date).format('DD.MM.YYYY');
				i.created_label = moment(i.created).format('HH:mm [//] DD.MM.YYYY');
				i.actions = () => <Fragment>
					<Button href={`https://chimpar-api.leonmarkus.de/invoice/pdf?session=${api.getSession()}&id=${i.id}`} newtab small icon="file" color={user.color} />
					<Button action href={`/invoices/${i.id}`} small icon="eye" color={user.color}>VIEW</Button>
				</Fragment>;
				return i;
			});
			this.setState({ invoices });
		});
	}
	
	render() {
		const { user } = this.props;
		const { customer } = this.state;

		return (
			<Fragment>
				<div className="main-caption">
					<Link to="/customers">Meine Kunden</Link> <Icon icon="circle" size={8} lineHeight={40} marginLeft={4} marginRight={4} /> { customer.name }
				</div>
				<div className="main-content">
					ID: { customer.id }<br />
					<br />
					Name: { customer.name }<br />
					Country: { customer.country_name }<br />
					PLZ: { customer.plz }<br />
					City: { customer.city }<br />
					Address: { customer.address }<br />
					<br />
					E-Mail: { customer.email }<br />
					<br />
					Edited: { customer.edited_label }<br />
					Created: { customer.created_label }<br />
					<br />
					<hr />
					<br />
					<Table
						primary={user.color}
						data={this.state.invoices}
						headings={[
							{ id: <Fragment><Icon icon="hashtag" size="12" /> ID</Fragment> },
							{ date_label: <Fragment><Icon icon="calendar-alt" size="12" /> DATE</Fragment> },
							{ comment: <Fragment><Icon icon="comment" size="12" /> COMMENT</Fragment> },
							{ created_label: <Fragment><Icon icon="calendar-alt" size="12" /> CREATED</Fragment> },
							{ actions: '' }
						]}
						alignCenters={['id', 'date_label']}
						alignRights={['created_label', 'actions']}
						sortables={{
							id: 'string',
							date_label: { type: 'date', key: 'date' },
							comment: 'string',
							created_label: { type: 'datetime', key: 'created' }
						}}
						initSortable="date_label"
						sortablesUpdater={invoices => this.setState({ invoices })}
					/>
				</div>
			</Fragment>
		);
	}
}