import React, { Component, Fragment } from 'react';
//import { Transition, animated } from 'react-spring';

import Input from 'components/Input';
import Button from 'components/Button';

export default class EmailAccounts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			updatePasswordData: {
				email: null,
				password: null
			}
		};

		this.updatePassword = this.updatePassword.bind(this);
	}
	
	render() {
		return (
			<Fragment>
				<div className="main-caption">
                    EmailAccounts
				</div>
				<div className="main-content">
					Hello World from Chimpar!<br />
					This is <b>EmailAccounts</b>!
					<br /><br />
					Update Password<br />
					<Input type="email" value={this.state.updatePasswordData.email} label="E-Mail" onChange={val => this.setState({ updatePasswordData: { ...this.state.updatePasswordData, email: val } })} />
					<Input type="password" value={this.state.updatePasswordData.password} label="Password" onChange={val => this.setState({ updatePasswordData: { ...this.state.updatePasswordData, password: val } })} />
					<Button icon="pen" iconSize="20" onClick={this.updatePassword} />
				</div>
			</Fragment>
		);
	}

	updatePassword() {
		const { api } = this.props;
		const { email, password } = this.state.updatePasswordData;

		api.get('/emailaccounts/password/override', {
			email,
			password
		}).then(() => {
			console.log('success');
		}).catch(err => console.error(err) );
	}
}