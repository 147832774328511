import Cookies from 'js-cookie';

class Storage {
	get(name) { return Cookies.get(name); }

	set(name, value, expiration = undefined) { Cookies.set(name, value, { expires: expiration }); }

	del(name) { Cookies.remove(name); }
}

export default Storage;