import moment from 'moment';

/*
 * ACCEPTS: string, number, time (string), date (string), datetime (string), moment, object (sorting object), function (sorting function)
 */
export default function sortFunction(data = [], type, key, dir = 'asc') {
	let func;

	if (type == 'number') {
		func = (a, b) => (a[key] < b[key]) ? -1 : ((a[key] > b[key]) ? 1 : 0);
	} else if (type == 'string') {
		func = (a, b) => (a[key].toLowerCase() < b[key].toLowerCase()) ? -1 : ((a[key].toLowerCase() > b[key].toLowerCase()) ? 1 : 0);
	} else if (type == 'time' || type == 'date' || type == 'datetime' || type == 'moment') {
		func = (a, b) => moment(b[key]).diff(a[key]);
	} else if (typeof type == 'object') {
		const sortingObject = type; // SORTING OBJECT IS PASSED { type: '...', key: '...'}
		return sortFunction(data, sortingObject.type, sortingObject.key, dir);
	} else if (typeof type == 'function') {
		func = type; // SORTING FUNCTION IS PASSED
	} else {
		func = () => -1; // DO NO SORTING
	}
	
	const output = data.sort(func);
	if (dir == 'desc') return output.reverse();
	return output;
}