import axios from 'axios';

class Api {
	constructor(session) { this.session = session; }

	getSession() { return this.session; }
	setSession(session) { this.session = session; }
	removeSession() { this.session = undefined; }

	get(endpoint, params) { return this.call('GET', endpoint, params); }
	post(endpoint, params) { return this.call('POST', endpoint, params); }
	put(endpoint, params) { return this.call('PUT', endpoint, params); }
	delete(endpoint, params) { return this.call('DELETE', endpoint, params); }

	call(method , url, data = {}) {
		return new Promise((resolve, reject) => {
			// MAKE SURE THAT DATA PARAMETER IS DEFINED
			if (data == null) data = {};

			// ADD SESSSION TO DATA ==> deactivated in favor for bearer token
			//data.session = this.session;

			// ATTACH DATA (POST/PUT/DELETE) OR URL PARAMS (GET)
			let params = null;
			if (method === 'GET') {
				params = data;
				data = null;
			}

			axios({
				method,
				baseURL: 'https://chimpar-api.leonmarkus.de',
				url,
				data,
				params,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': this.session ? `Bearer ${this.session}` : undefined
				},
				validateStatus: status => status === 200
			}).then(response => {
				const res = response.data;

				if(typeof res.data == 'undefined') resolve();
				else resolve(res.data);
			}).catch(err => {
				if (typeof err.response != 'undefined') {
					const status = err.response.status;
					const res = err.response.data;

					if (status == 403) {
						this.error403();
						reject(res.error);
					} else {
						this.errorUnknown(status, res);
						reject(res.error);
					}
				} else reject('offline');
			});
		});
	}

	error403() {
		//showPopup('403 - Access Denied', "You haven't got the permission to perform this action.<br><small><i>Note: Contact a supervisor to obtain access.</i></small>");
		//go('base.home');
		console.error('403 - Access Denied', 'You haven\'t got the permission to perform this action.<br><small><i>Note: Contact a supervisor to obtain access.</i></small>');
	}
	errorUnknown(status, res) {
		//showPopup('ERROR '+status, res.error + ((typeof res.params != 'undefined' && typeof res.params.msg != 'undefined') ? (' - '+res.params.msg) : ''));
		console.error('ERROR '+status, res.error + ((typeof res.params != 'undefined' && typeof res.params.msg != 'undefined') ? (' - '+res.params.msg) : ''));
	}
}

export default Api;