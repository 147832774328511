import React, { Component } from 'react';

export default class Loader extends Component {
	render() {
		const { size = 38 } = this.props;
        
		const diameter = size - 2;
		const half = diameter / 2;

		return (
			<svg width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size} ${size}`} stroke="#FFFFFF">
				<g transform="translate(1 1)" strokeWidth={2} fill="none" fillRule="evenodd">
					<circle strokeOpacity=".5" cx={half} cy={half} r={half} />
					<path d={`M${diameter} ${half}c0-9.94-8.06-${half}-${half}-${half}`}>
						<animateTransform
							attributeName="transform"
							type="rotate"
							from={`0 ${half} ${half}`}
							to={`360 ${half} ${half}`}
							dur="1s"
							repeatCount="indefinite"/>
					</path>
				</g>
			</svg>
		);
	}
}