import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

// UTILS
import sortFunction from 'utils/sort';

// IMAGES
import SortArrows from 'images/sort-arrows.svg';
import SortArrowsAsc from 'images/sort-arrows-asc.svg';
import SortArrowsDesc from 'images/sort-arrows-desc.svg';

export default class Table extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sortablesMeta: {}
		};
	}
	
	componentDidUpdate() {
		if (Object.keys(this.state.sortablesMeta).length < 1) {
			const { initSortable, sortables = {} } = this.props;
			if (typeof initSortable == 'string' && Object.keys(sortables).indexOf(initSortable) !== -1) {
				this.sort(sortables[initSortable], initSortable, 'asc');
			} else if (typeof initSortable == 'object' && Object.keys(sortables).indexOf(initSortable.key) !== -1) {
				this.sort(sortables[initSortable.key], initSortable.key, initSortable.dir);
			}
		}
	}
	
	render() {
		const { headings = [], data = [], footers = [], alignCenters = [], alignRights = [], sortables = {}, sortablesUpdater, primary: $primary } = this.props;
		
		return (
			<table className="table">
				{ headings.length > 0 && <thead>
					<tr>
						{ headings.map((headingObj, index) => {
							const headingKey = Object.keys(headingObj)[0];
							const headingLabel = Object.values(headingObj)[0];

							let classes = [];
							if (alignCenters.indexOf(headingKey) !== -1) classes.push('text-center');
							else if (alignRights.indexOf(headingKey) !== -1) classes.push('text-right');
							
							let sortable = false;
							if (typeof sortablesUpdater == 'function' && Object.keys(sortables).indexOf(headingKey) !== -1) sortable = true;

							return (<th key={index} className={classes.join(' ')} style={{ color: $primary}}>
								{ sortable && <span className="sortable" onClick={() => {
									const dir = (this.state.sortablesMeta[headingKey] == 'asc') ? 'desc' : 'asc';
									
									this.sort(sortables[headingKey], headingKey, dir);
								}}>
									{ headingLabel }
									<img src={(this.state.sortablesMeta[headingKey] == 'asc') ? SortArrowsAsc : (this.state.sortablesMeta[headingKey] == 'desc' ? SortArrowsDesc : SortArrows)} />
								</span> }
								{ !sortable && headingLabel }
							</th>);
						})}
					</tr>
				</thead> }
				<tbody>
					{ data.length > 0 && data.map((row, rowIndex) => {
						return (
							<tr key={rowIndex}>
								{ headings.map((headingObj, cellIndex) => {
									const headingKey = Object.keys(headingObj)[0];
									if (Object.keys(row).indexOf(headingKey) !== -1) {
										let alignment = '';
										if (alignCenters.indexOf(headingKey) !== -1) alignment = 'text-center';
										else if (alignRights.indexOf(headingKey) !== -1) alignment = 'text-right';

										if (typeof row[headingKey] == 'object') {
											return (
												<td key={cellIndex} className={alignment}>
													<div className="heading" style={{ color: $primary }}>
														{ Object.values(headings.find(h => Object.keys(h)[0] === headingKey))[0] }
													</div>
													<Link to={row[headingKey].link} className="link">{ row[headingKey].value }</Link>
												</td>
											);
										} else if (headingKey === 'actions') {
											const Action = row[headingKey];
											return (<td key={cellIndex} className={alignment}><Action /></td>);
										} else {
											let html = `<div class="heading" style="color: ${$primary};">${Object.values(headings.find(h => Object.keys(h)[0] === headingKey))[0]}</div>`;
											html += row[headingKey];

											return (<td key={cellIndex} dangerouslySetInnerHTML={{__html: html}} className={alignment}></td>);
										}
									} else return null;
								})}
							</tr>
						);
					})}
				</tbody>
				{ footers.length > 0 && <tfoot>
					<tr>
						{ footers.map((footerObj, index) => {
							const footerKey = Object.keys(footerObj)[0];
							const footerLabel = Object.values(footerObj)[0];

							let alignment = null;
							if (alignCenters.indexOf(footerKey) !== -1) alignment = 'text-center';
							else if (alignRights.indexOf(footerKey) !== -1) alignment = 'text-right';

							return (<th key={index} className={alignment} style={{ color: $primary}}>{ footerLabel }</th>);
						})}
					</tr>
				</tfoot> }
			</table>
		);
	}

	sort(type, key, dir) {
		const { data, sortablesUpdater = () => {} } = this.props;
		sortablesUpdater(sortFunction(data, type, key, dir)); 
		this.setState({ sortablesMeta: { [key]: dir} });
	}
}