import React, { Component, Fragment } from 'react';
import moment from 'moment';
//import { Transition, animated } from 'react-spring';

// COMPONENTS
import Icon from 'components/Icon';
import Button from 'components/Button';
import Table from 'components/Table';

export default class Customers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			customers: []
		};
	}

	componentDidMount() {
		const { api, user } = this.props;

		api.get('/customers').then(mCustomers => {
			const customers = mCustomers.map(c => {
				c.edited_label = c.edited ? moment(c.edited).format('HH:mm [//] DD.MM.YYYY') : '';
				c.created_label = moment(c.created).format('HH:mm [//] DD.MM.YYYY');
				c.actions = () => <Button href={`/customers/${c.id}`} small icon="eye" color={user.color}>VIEW</Button>;
				return c;
			});
			this.setState({ customers });
		});
	}
	
	render() {
		const { user } = this.props;

		return (
			<Fragment>
				<div className="main-caption">
					Meine Kunden
					<div className="action-buttons">
						<Button circle color={user.color} nohover icon="plus" iconSize="20" onClick={() => alert('Add Customer')} />
					</div>
				</div>
				<div className="main-content">
					<Table
						primary={user.color}
						data={this.state.customers}
						headings={[
							{ id: <Fragment><Icon icon="hashtag" size="12" /> ID</Fragment> },
							{ name: <Fragment><Icon icon="user-circle" size="12" /> NAME</Fragment> },
							{ email: <Fragment><Icon icon="envelope" size="12" /> E-MAIL</Fragment> },
							{ phone: <Fragment><Icon icon="phone" flipHorizontal size="12" /> PHONE</Fragment> },
							{ country_short: <Fragment><Icon icon="globe" size="12" /> COUNTRY</Fragment> },
							{ created_label: <Fragment><Icon icon="calendar-alt" size="12" /> CREATED</Fragment> },
							{ actions: '' }
						]}
						alignCenters={['id', 'country_short']}
						alignRights={['created_label', 'actions']}
						sortables={{
							id: 'string',
							name: 'string',
							email: 'string',
							phone: 'string',
							country_short: 'string',
							created_label: { type: 'datetime', key: 'created' }
						}}
						initSortable="name"
						sortablesUpdater={customers => this.setState({ customers })}
					/>
				</div>
			</Fragment>
		);
	}
}