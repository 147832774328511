import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
//import { Transition, animated } from 'react-spring';

// COMPONENTS
import Icon from 'components/Icon';
import Button from 'components/Button';
import Table from 'components/Table';
import Modal from 'components/Modal';

export default class Invoice extends Component {
	constructor(props) {
		super(props);

		this.state = {
			invoice: {},

			deleteShowModal: false
		};

		this.deleteInvoice = this.deleteInvoice.bind(this);
		this.deleteInvoiceClose = this.deleteInvoiceClose.bind(this);
		this.deleteInvoiceConfirm = this.deleteInvoiceConfirm.bind(this);
	}

	componentDidMount() {
		const { router, api } = this.props;
		const { id } = router.params;

		api.get('/invoice', { id }).then(mInvoice => {
			const invoice = {
				...mInvoice,
				date_label: mInvoice.date ? moment(mInvoice.date).format('DD.MM.YYYY') : '',
				created_label: mInvoice.created ? moment(mInvoice.created).format('HH:mm [//] DD.MM.YYYY') : ''
			};
			this.setState({ invoice });
			console.log(invoice); // DBG
		});
	}
	
	render() {
		const { router, api, user } = this.props;
		const { invoice } = this.state;

		return (
			<Fragment>
				<div className="main-caption">
					<Link to="/invoices">Rechnungen</Link> <Icon icon="circle" size={8} lineHeight={40} marginLeft={4} marginRight={4} /> { invoice.id }
					
					<div className="action-buttons">
						<Button circle small color="cherry-tomato" nohover icon="trash" iconSize="20" onClick={this.deleteInvoice} />
						<Button href={`https://chimpar-api.leonmarkus.de/invoice/pdf?session=${api.getSession()}&id=${router.params.id}`} newtab circle color="little-boy-blue" nohover icon="download" iconSize="20" />
					</div>
				</div>
				<div className="main-content">
					Customer: <Link to={`/customers/${invoice.customer}`}>{ invoice.customer_name }</Link><br />
					<br />
					Total: { invoice.total }<br />
					<br />
					Comment: { invoice.comment }<br />
					<br />
					Date: { invoice.date_label }<br />
					Created: { invoice.created_label }<br />
					<br />
					<Table
						primary={user.color}
						data={invoice.items}
						headings={[
							{ count: <Fragment><Icon icon="hashtag" size="12" /> AMOUNT</Fragment> },
							{ unit: <Fragment><Icon icon="clone" size="12" /> UNIT</Fragment> },
							{ service: <Fragment><Icon icon="comment" size="12" /> SERVICE</Fragment> },
							{ unit_price: <Fragment><Icon icon="money-bill" size="12" /> UNIT PRICE</Fragment> },
							{ price: <Fragment><Icon icon="money-bill" size="12" /> PRICE</Fragment> }
						]}
						alignCenters={['count']}
						alignRights={['unit_price', 'price']}
						sortables={{
							count: 'string',
							unit: 'string',
							service: 'string',
							unit_price: 'string',
							price: 'string',
						}}
					/>
				</div>

				{ this.state.deleteShowModal && <Modal
					title="Delete Invoice"
					closeButton="Abort"
					onClose={this.deleteInvoiceClose}
					confirmButton="Delete Invoice"
					onConfirm={this.deleteInvoiceConfirm}>
					Are you sure?<br />
					<span className="note">NOTE: This can not be undone</span>
				</Modal> }
			</Fragment>
		);
	}

	deleteInvoice() { this.setState({ deleteShowModal: true }); }
	deleteInvoiceClose() { this.setState({ deleteShowModal: false }); }
	deleteInvoiceConfirm() {
		const { router, api } = this.props;
		const { id } = router.params;
		api.delete('/invoice', {
			id
		}).then(() => {
			this.deleteInvoiceClose();
			router.push('/invoices');
		}).catch(err => console.error(err) );
	}
}