import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Grid, Cell } from 'styled-css-grid';
//import { Transition, animated } from 'react-spring';

// COMPONENTS
import Icon from 'components/Icon';
import Button from 'components/Button';
import Table from 'components/Table';
import Modal from 'components/Modal';
import Input from 'components/Input';

export default class Invoices extends Component {
	constructor(props) {
		super(props);

		this.state = {
			invoices: [],
			
			addInvoiceObject: {
				showModal: false
			}
		};
		
		this.init = this.init.bind(this);
    
		this.addInvoice = this.addInvoice.bind(this);
		this.addInvoiceChange = this.addInvoiceChange.bind(this);
		this.addInvoiceClose = this.addInvoiceClose.bind(this);
		this.addInvoiceConfirm = this.addInvoiceConfirm.bind(this);
		this.addInvoiceAddRow = this.addInvoiceAddRow.bind(this);
		this.addInvoiceDeleteRow = this.addInvoiceDeleteRow.bind(this);
		this.addInvoiceChangeRow = this.addInvoiceChangeRow.bind(this);
	}

	componentDidMount() { this.init(); }
	
	render() {
		const { user } = this.props;
		const { invoices, addInvoiceObject } = this.state;

		return (
			<Fragment>
				<div className="main-caption">
					Meine Rechnungen
					<div className="action-buttons">
						<Button circle nohover color={user.color} icon="plus" iconSize="20" onClick={this.addInvoice} />
					</div>
				</div>
				<div className="main-content">
					<Table
						primary={user.color}
						data={invoices}
						headings={[
							{ id: <Fragment><Icon icon="hashtag" size="12" /> ID</Fragment> },
							{ customer_link: <Fragment><Icon icon="user-circle" size="12" /> CUSTOMER</Fragment> },
							{ date_label: <Fragment><Icon icon="calendar-alt" size="12" /> DATE</Fragment> },
							{ comment: <Fragment><Icon icon="comment" size="12" /> COMMENT</Fragment> },
							{ created_label: <Fragment><Icon icon="calendar-alt" size="12" /> CREATED</Fragment> },
							{ actions: '' }
						]}
						alignCenters={['id', 'customer_link', 'date_label']}
						alignRights={['created_label', 'actions']}
						sortables={{
							id: 'string',
							customer_link: { type: 'string', key: 'customer_name' },
							date_label: { type: 'date', key: 'date' },
							comment: 'string',
							created_label: { type: 'datetime', key: 'created' }
						}}
						initSortable="created_label"
						sortablesUpdater={invoices => this.setState({ invoices })}
					/>
				</div>

				{ addInvoiceObject.showModal && <Modal 
					big
					title="Add Invoice"
					closeButton="Abort"
					onClose={this.addInvoiceClose}
					confirmButton="Add Invoice"
					onConfirm={this.addInvoiceConfirm}>
					<Grid gap="16px">
						<Cell width={6}>
							<Input
								type="select"
								label="Name/Company"
								value={addInvoiceObject.customer}
								onChange={value => this.addInvoiceChange('customer', value)}
								options={addInvoiceObject.customersSelection} />
						</Cell>
						<Cell width={6}>
							<Input
								type="date"
								label="Effective Date"
								value={addInvoiceObject.date}
								onChange={value => this.addInvoiceChange('date', value)} />
						</Cell>
					</Grid>
					<Input
						type="textarea"
						label="Comment"
						value={addInvoiceObject.comment}
						onChange={value => this.addInvoiceChange('comment', value)} />
					<br /><br /><br /><br />
					{ addInvoiceObject.items.map((item, index) => <Grid
						key={'index'+index}
						gap="12px"
						style={{ borderTop: '1px solid rgba(120, 132, 164, 0.2)' }}>
						<Cell width={1}>
							<Input label="Count" value={item.count} onChange={value => this.addInvoiceChangeRow('count', value, index)} />
						</Cell>
						<Cell width={2}>
							<Input label="Unit" value={item.unit} onChange={value => this.addInvoiceChangeRow('unit', value, index)} />
						</Cell>
						<Cell width={4}>
							<Input label="Service" value={item.service} onChange={value => this.addInvoiceChangeRow('service', value, index)} />
						</Cell>
						<Cell width={2}>
							<Input label="Price/Unit" value={item.unit_price} onChange={value => this.addInvoiceChangeRow('unit_price', value, index)} />
						</Cell>
						<Cell width={2}>
							<Input label="Price" disabled value={(Math.floor(item.count*item.unit_price*100)/100).toFixed(2)} />
						</Cell>
						<Cell width={1} middle>
							<Button small color="cherry-tomato" icon="eraser" onClick={() => this.addInvoiceDeleteRow(index)} />
						</Cell>
					</Grid> )}
					<div className="text-center" style={{ paddingTop: '16px', borderTop: '1px solid rgba(120, 132, 164, 0.2)' }}>
						<Button small outline icon="plus" onClick={this.addInvoiceAddRow}>Add Row</Button>
					</div>
				</Modal> }
			</Fragment>
		);
	}

	init() {
		const { api, user } = this.props;

		api.get('/invoices').then(mInvoices => {
			const invoices = mInvoices.map(i => {
				i.customer_link = { link: `/customers/${i.customer}`, value: i.customer_name };
				i.date_label = moment(i.date).format('DD.MM.YYYY');
				i.created_label = moment(i.created).format('HH:mm [//] DD.MM.YYYY');
				i.actions = () => <Fragment>
					<Button href={`https://chimpar-api.leonmarkus.de/invoice/pdf?session=${api.getSession()}&id=${i.id}`} newtab small icon="download" color={user.color} />
					<Button action href={`/invoices/${i.id}`} small icon="eye" color={user.color}>VIEW</Button>
				</Fragment>;
				return i;
			});
      
			this.setState({ invoices });
		});
	}

	addInvoice() {
		const { api } = this.props;
		api.get('customers').then(mCustomers => {
			const customersSelection = mCustomers.map(c => ({
				value: c.id,
				label: c.name
			}));

			this.setState({ addInvoiceObject: {
				customersSelection,
				customer: customersSelection[0],
				date: moment().format('YYYY-MM-DD'),
				comment: '',
				items: [
					{ count: 1, unit: '', service: '', unit_price: 0 }
				],

				showModal: true
			} });
		});
	}
	addInvoiceClose() { this.setState({ addInvoiceObject: { ...this.state.addInvoiceObject, showModal: false } }); }
	addInvoiceConfirm() {
		if (
			typeof this.state.addInvoiceObject.customer == 'undefined' || this.state.addInvoiceObject.customer == null || 
			typeof this.state.addInvoiceObject.date == 'undefined' || this.state.addInvoiceObject.date == null || this.state.addInvoiceObject.date == '' ||
			typeof this.state.addInvoiceObject.items == 'undefined' || this.state.addInvoiceObject.items == null || this.state.addInvoiceObject.items.length < 1
		) {
			console.error('[ADD INVOICE]', 'invalid field', this.state.addInvoiceObject);
		} else {
			const { api } = this.props;
			const { showModal, customersSelection, ...invoice } = this.state.addInvoiceObject;
			
			api.put('/invoice', invoice).then(id => {
				console.log(id);
				this.init();
				this.addInvoiceClose();
			});
		}
	}
	addInvoiceAddRow() {
		this.setState({ addInvoiceObject: {
			...this.state.addInvoiceObject,
			items: [
				...this.state.addInvoiceObject.items,
				{ count: 1, unit: '', service: '', unit_price: 0 }
			]
		}});
	}
	addInvoiceDeleteRow(index) {
		let items = [...this.state.addInvoiceObject.items];
		items.splice(index, 1);
		this.setState({ addInvoiceObject: {
			...this.state.addInvoiceObject,
			items
		}});
	}
	addInvoiceChange(key, value) {
		this.setState({ addInvoiceObject: {
			...this.state.addInvoiceObject,
			[key]: value
		}});
	}
	addInvoiceChangeRow(itemKey, itemValue, index) {
		this.setState({ addInvoiceObject: {
			...this.state.addInvoiceObject,
			items: [
				...this.state.addInvoiceObject.items.slice(0, index),
				{ ...this.state.addInvoiceObject.items[index], [itemKey]: itemValue },
				...this.state.addInvoiceObject.items.slice(index+1)
			]
		}});
	}
}