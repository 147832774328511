import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';

const portalRoot = document.getElementById('modal');

// STYLES
import './styles.scss';

// COMPONENTS
import Button from 'components/Button';

export default class Modal extends Component {
	componentDidMount() {
		const { onInit: doInit = () => {} } = this.props;
		doInit();
	}

	render() {
		const {
			title,
			children: content,
			closeButton = 'Okay',
			confirmButton,
			onClose: doClose = () => {},
			onConfirm: doConfirm = () => {},
			big,
			fullscreen
		} = this.props;

		let modalDialogClasses = ['modal-dialog'];
		if (fullscreen) modalDialogClasses.push('fullscreen');
		else if (big) modalDialogClasses.push('big');

		return ReactDOM.createPortal(
			<Fragment>
				<div className="modal-backdrop" />
				<div className="modal">
					<div className={modalDialogClasses.join(' ')}>
						<div className="modal-title">
							{ title }
							<span className="modal-close" onClick={doClose}><i className="fas fa-times"></i></span>
						</div>
						<div className="modal-content">{ content }</div>
						<div className="modal-actions">
							<Button color={confirmButton ? 'default' : 'little-boy-blue'} onClick={doClose}>{ closeButton }</Button>
							{ confirmButton && <Button modal color="little-boy-blue" onClick={doConfirm}>{ confirmButton }</Button> }
						</div>
					</div>
				</div>
			</Fragment>
			, portalRoot);
	}
}